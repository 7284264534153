<script setup>
import {useBaseStore} from "~/stores/base";
import Drawer from "~/components/Drawer.vue";
import {menuIndex} from "~/api/api.menu";
import ModuleBrands from "~/components/DrawerMenu/ModuleBrands.vue";
import ModuleCategories from "~/components/DrawerMenu/ModuleCategories.vue";
import ModuleViavia from "~/components/DrawerMenu/ModuleViavia.vue";
import {ELEMENT_LABEL, ELEMENT_TYPE, PAGE_LABEL, smartLookTrackPageView} from "~/utils/smartlook";

const router = useRouter()

const isShowBrands = ref(false)
const isShowCategories = ref(false)
const isShowViavia = ref(false)
const origin = import.meta.env.VITE_ORIGIN_WEBSITE

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
const isShowDrawerMenu = computed(() => useBaseStore().getterIsShowDrawerMenu)

/** watch **/
watch(isShowDrawerMenu, () => {
  if (isShowDrawerMenu.value) {
    useBaseStore().updatePageLabel(PAGE_LABEL.MENU)
    smartLookTrackPageView()
  }
})

/** methods **/
const handleCellClick = (item) => {
  if (item.link) {
    router.push(item.link)
  } else {
    handleArrowClick(item)
  }
}

const handleArrowClick = (item) => {
  switch (item.title) {
    case "Brands":
      isShowBrands.value = true;
      break;
    case "Categories":
      isShowCategories.value = true;
      break;
    case "VIAVIA":
      isShowViavia.value = true;
      break;
    default:
      break;
  }
}

const handleCloseMenu = () => {
  isShowBrands.value = false;
  isShowCategories.value = false;
  isShowViavia.value = false;
  useBaseStore().updateIsShowDrawerMenu(false)
}

/** 获取数据 **/
const { data } = await useAsyncData('menuIndex', menuIndex, {
  default: () => ({
    result: {
      imageList: [],
      menuList: []
    }
  })
})
console.log("menuIndex", data.value)

const imageList = computed(() => data.value.result.imageList || [])
const menuList = computed(() => data.value.result.menuList || [])

</script>

<template>
  <div class="drawer-menu-mobile" v-if="isMobile">
    <Drawer :open="isShowDrawerMenu" :position="'left'" :z-index="3" @close="handleCloseMenu">
      <div class="menu">
        <!-- menu -->
        <div class="block-menu" v-if="menuList.length">
          <template :key="index" v-for="(item, index) in menuList">
            <!-- 有二级 -->
            <div
              class="menu-cell"
              @click="handleCellClick(item)"
              v-if="item.next"
              element-click-observe-async
              :element-type="ELEMENT_TYPE.BUTTON"
              :element-title="`Text ${item.title}`"
              :element-label="ELEMENT_LABEL.MENU"
            >
              <div class="cell-left">
                {{ item.title }}
              </div>
              <div class="cell-right" @click.stop="handleArrowClick(item)">
                <i class="iconfont icon-arrow-right"></i>
              </div>
            </div>
            <!-- 没二级 -->
            <nuxt-link
              class="menu-cell"
              :to="item.link"
              v-else
              element-click-observe-async
              :element-type="ELEMENT_TYPE.LINK"
              :element-title="`Text ${item.title}`"
              :element-label="ELEMENT_LABEL.MENU"
            >
              <div class="cell-left">
                {{ item.title }}
              </div>
            </nuxt-link>
          </template>
        </div>

        <!-- image -->
        <div class="block-image" v-if="imageList.length">
          <nuxt-link
            :to="item.link"
            :key="index"
            v-for="(item, index) in imageList"
            element-click-observe-async
            :element-type="ELEMENT_TYPE.IMAGE"
            :element-title="`Image ${index}`"
            :element-label="ELEMENT_LABEL.MENU"
          >
            <img :src="item.cover.url" alt="image">
          </nuxt-link>
        </div>
      </div>

      <!-- brands -->
      <module-brands v-if="isShowBrands" @close="() => {isShowBrands = false}"/>

      <!-- categories -->
      <module-categories v-if="isShowCategories" @close="() => {isShowCategories = false}"/>

      <!-- VIAVIA -->
      <module-viavia v-if="isShowViavia" @close="() => {isShowViavia = false}"/>
    </Drawer>
  </div>
  <div class="drawer-menu-desktop" v-else>
    <Drawer :open="isShowDrawerMenu" :position="'left'" :z-index="3" @close="handleCloseMenu">
      <div class="menu">
        <!-- menu -->
        <div class="block-menu" v-if="menuList.length">
          <template :key="index" v-for="(item, index) in menuList">
            <!-- 有二级 -->
            <div
              class="menu-cell"
              @click="handleCellClick(item)"
              v-if="item.next"
              element-click-observe-async
              :element-type="ELEMENT_TYPE.BUTTON"
              :element-title="`Text ${item.title}`"
              :element-label="ELEMENT_LABEL.MENU"
            >
              <div class="cell-left">
                {{ item.title }}
              </div>
              <div class="cell-right" @click.stop="handleArrowClick(item)">
                <i class="iconfont icon-arrow-right"></i>
              </div>
            </div>
            <!-- 没二级 -->
            <nuxt-link
              class="menu-cell"
              :to="item.link"
              v-else
              element-click-observe-async
              :element-type="ELEMENT_TYPE.LINK"
              :element-title="`Text ${item.title}`"
              :element-label="ELEMENT_LABEL.MENU"
            >
              <div class="cell-left">
                {{ item.title }}
              </div>
            </nuxt-link>
          </template>
        </div>

        <!-- image -->
        <div class="block-image" v-if="imageList.length">
          <nuxt-link
            :to="item.link"
            :key="index"
            v-for="(item, index) in imageList"
            element-click-observe-async
            :element-type="ELEMENT_TYPE.IMAGE"
            :element-title="`Image ${index}`"
            :element-label="ELEMENT_LABEL.MENU"
          >
            <img :src="item.cover.url" alt="image">
          </nuxt-link>
        </div>
      </div>

      <!-- brands -->
      <module-brands v-if="isShowBrands" @close="() => {isShowBrands = false}"/>

      <!-- categories -->
      <module-categories v-if="isShowCategories" @close="() => {isShowCategories = false}"/>

      <!-- VIAVIA -->
      <module-viavia v-if="isShowViavia" @close="() => {isShowViavia = false}"/>
    </Drawer>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.drawer-menu-mobile {
  .menu {
    width: 375px;
    height: calc(100vh - $mobile-header-height);
    overflow-y: auto;
    padding-bottom: $mobile-safari-address-bar-height;

    .block-menu {
      padding: 16px;
    }

    .block-image {
      margin-top: 32px;
      background-color: $color-gray-100;
      padding: 16px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px;

      a {
        display: flex;

        img {
          pointer-events: none;
          width: 166px;
          height: 207.5px;
          object-fit: contain;
          object-position: center center;
        }
      }
    }
  }
}

.drawer-menu-desktop {
  .menu {
    width: 375px;
    height: calc(100vh - $desktop-header-height);
    overflow-y: auto;

    .block-menu {
      padding: 16px;
    }

    .block-image {
      margin-top: 32px;
      background-color: $color-gray-100;
      padding: 16px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px;

      a {
        display: flex;

        img {
          pointer-events: none;
          width: 158px;
          height: 197.5px;
          object-fit: contain;
          object-position: center center;
        }
      }
    }
  }
}
</style>
